import React, { useState, useEffect, useCallback } from "react";
import questions from "./questions.json";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import MainContent from "./components/MainContent";
import ReactGA from "react-ga4";

export default function App() {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedSection, setSelectedSection] = useState("all");
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [hint, setHint] = useState("");
    const [solution, setSolution] = useState("");
    const [aiGenerated, setAiGenerated] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [showSolution, setShowSolution] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [showTimer, setShowTimer] = useState(false);

    useEffect(() => {
        const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
        if (trackingId) {
            ReactGA.initialize(trackingId);
            ReactGA.send({
                hitType: "pageview",
                pagePath: window.location.pathname,
            });
        } else {
            console.error("Google Analytics tracking ID is not set");
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (filteredQuestions.length > 0) {
            setHint(filteredQuestions[currentQuestionIndex].hint || "");
            setSolution(filteredQuestions[currentQuestionIndex].solution || "");
            setAiGenerated(
                filteredQuestions[currentQuestionIndex].ai_generated === "True"
            );
        }
    }, [filteredQuestions, currentQuestionIndex]);

    const filterQuestions = useCallback(() => {
        let newFilteredQuestions = [];

        if (selectedSection === "all") {
            newFilteredQuestions = [
                ...questions.brainteasers,
                ...questions.calculus_and_linear_algebra,
                ...questions.probability_theory,
                ...questions.stochastic_processes_and_stochastic_calculus,
                ...questions.algorithms_and_numerical_methods,
                ...questions.finance,
            ];
        } else {
            newFilteredQuestions = questions[selectedSection] || [];
        }

        setFilteredQuestions(() => {
            if (newFilteredQuestions.length > 0) {
                setCurrentQuestionIndex(0);
                setHint(newFilteredQuestions[0].hint || "");
                setSolution(newFilteredQuestions[0].solution || "");
                setAiGenerated(newFilteredQuestions[0].ai_generated === "True");
            } else {
                setHint("");
                setSolution("");
                setAiGenerated(false);
            }
            setShowSolution(false);
            setShowHint(false);

            return newFilteredQuestions;
        });
    }, [selectedSection]);

    useEffect(() => {
        filterQuestions();
    }, [filterQuestions]);

    const getNextQuestion = () => {
        const randomIndex = Math.floor(
            Math.random() * filteredQuestions.length
        );
        setCurrentQuestionIndex(randomIndex);
        setShowHint(false);
        setShowSolution(false);
        setSeconds(0);
    };

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
    };

    return (
        <div className="relative bg-white min-h-screen flex flex-col">
            <div className="flex flex-grow">
                <Sidebar onSectionSelect={handleSectionSelect} />
                <MainContent
                    onSectionSelect={handleSectionSelect}
                    seconds={seconds}
                    showTimer={showTimer}
                    setShowTimer={setShowTimer}
                    question={filteredQuestions[currentQuestionIndex]?.question}
                    questionName={filteredQuestions[currentQuestionIndex]?.name}
                    aiGenerated={aiGenerated}
                    solution={solution}
                    showSolution={showSolution}
                    showHint={showHint}
                    setShowHint={setShowHint}
                    hint={hint}
                    setShowSolution={setShowSolution}
                    getNextQuestion={getNextQuestion}
                />
            </div>
            <Footer className="w-full mt-auto" />
        </div>
    );
}
