import React from "react";

export default function Sidebar({ onSectionSelect }) {
    const sections = [
        { key: "all", label: "All" },
        { key: "brainteasers", label: "Brainteasers" },
        {
            key: "calculus_and_linear_algebra",
            label: "Calculus & Linear Algebra",
        },
        { key: "probability_theory", label: "Probability Theory" },
        {
            key: "stochastic_processes_and_stochastic_calculus",
            label: "Stochastic Processes & Calculus",
        },
        {
            key: "algorithms_and_numerical_methods",
            label: "Algorithms & Numerical Methods",
        },
        { key: "finance", label: "Finance" },
    ];

    return (
        <div className="hidden sm:block fixed inset-y-0 left-0 z-30 w-64 bg-gray-800 p-4">
            {/* Sidebar Content */}
            <ul className="mt-12 overflow-y-auto">
                {sections.map((section) => (
                    <li
                        key={section.key}
                        className="text-white py-2 cursor-pointer hover:bg-gray-700 rounded-md px-2"
                        onClick={() => onSectionSelect(section.key)}
                    >
                        {section.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}
