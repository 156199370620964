import React from "react";

export default function ToolTip({ children, tooltip }) {
    return (
        <div className="relative flex items-center">
            {children}
            <div className="absolute top-0 right-full mr-2 whitespace-nowrap bg-gray-600 text-white text-xs rounded py-1 px-2 z-10 shadow-lg">
                {tooltip}
            </div>
        </div>
    );
}
