export default function Footer() {
    return (
        <footer className="bg-white w-full mt-4">
            <div className="flex justify-center sm:ml-64">
                <div className="mx-auto max-w-screen-lg px-6 md:flex md:items-center md:justify-center lg:px-8">
                    <div className="mt-2 md:order-1 md:mt-0 flex items-center justify-center space-x-2">
                        <p className="text-xs leading-5 text-gray-500">
                            Credit goes to Xinfeng Zhou, the author of the book
                        </p>
                        <span className="text-xs leading-5 text-gray-500">
                            |
                        </span>{" "}
                        <a
                            href="mailto:quantqateam@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-xs text-blue-500 hover:underline"
                        >
                            Provide feedback, submit new questions, or request a
                            new feature
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
