import React from "react";
import HintModal from "./HintModal";

export default function ControlButtons({
    hint,
    showHint,
    setShowHint,
    showSolution,
    setShowSolution,
    getNextQuestion,
}) {
    return (
        <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* Hint Button */}
            <button
                onClick={() => setShowHint(!showHint)}
                className={`${
                    hint ? "visible" : "invisible"
                } rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500`}
            >
                {showHint ? "Hide" : "Show"} Hint
            </button>

            <HintModal open={showHint} setOpen={setShowHint} hint={hint} />

            {/* Solution Button */}
            <button
                onClick={() => setShowSolution(!showSolution)}
                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
            >
                {showSolution ? "Hide" : "Show"} Solution
            </button>

            {/* Next Question Button */}
            <button
                onClick={getNextQuestion}
                className="text-sm font-semibold leading-6 text-gray-900 align-bottom"
            >
                Next question <span aria-hidden="true">→</span>
            </button>
        </div>
    );
}
