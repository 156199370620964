import React from "react";
import ToolTip from "./ToolTip";

export default function Timer({ seconds, showTimer, setShowTimer }) {
    return (
        <div>
            {showTimer ? (
                <div className="flex justify-between mb-4">
                    <div>
                        <p>{seconds}</p>
                    </div>
                    <ToolTip tooltip="Timer resets for each question.">
                        <button
                            onClick={() => setShowTimer(false)}
                            className="rounded-md bg-gray-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                        >
                            Hide Timer
                        </button>
                    </ToolTip>
                </div>
            ) : (
                <div className="flex justify-end mb-4">
                    <button
                        onClick={() => setShowTimer(true)}
                        className="rounded-md bg-gray-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                    >
                        Show Timer
                    </button>
                </div>
            )}
        </div>
    );
}
