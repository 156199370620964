import React from "react";
import Header from "./Header";
import Timer from "./Timer";
import QuestionDisplay from "./QuestionDisplay";
import ControlButtons from "./ControlButtons";

export default function MainContent({
    onSectionSelect,
    seconds,
    showTimer,
    setShowTimer,
    question,
    questionName,
    aiGenerated,
    solution,
    showSolution,
    showHint,
    setShowHint,
    hint,
    setShowSolution,
    getNextQuestion,
}) {
    return (
        <div className="flex-grow flex flex-col transition-all duration-300 sm:ml-64">
            <Header onSectionSelect={onSectionSelect} />
            <div className="flex-grow flex justify-center px-4 py-8 sm:py-16 lg:py-16">
                <div className="w-full max-w-screen-lg mx-auto">
                    <div className="text-center">
                        <Timer
                            seconds={seconds}
                            showTimer={showTimer}
                            setShowTimer={setShowTimer}
                        />

                        <QuestionDisplay
                            question={question}
                            questionName={questionName}
                            aiGenerated={aiGenerated}
                            solution={solution}
                            showSolution={showSolution}
                        />

                        <ControlButtons
                            hint={hint}
                            showHint={showHint}
                            setShowHint={setShowHint}
                            showSolution={showSolution}
                            setShowSolution={setShowSolution}
                            getNextQuestion={getNextQuestion}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
