// components/Header.js
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import HelpModal from "./HelpModal";

const navigation = [
    {
        name: "👉 Access the quant prep book 👈",
        href: "https://usermanual.wiki/Document/Practical20Guide20To20Quantitative20Finance20Interview.604244935.pdf",
    },
];

const sections = [
    { key: "all", label: "All" },
    { key: "brainteasers", label: "Brainteasers" },
    {
        key: "calculus_and_linear_algebra",
        label: "Calculus & Linear Algebra",
    },
    { key: "probability_theory", label: "Probability Theory" },
    {
        key: "stochastic_processes_and_stochastic_calculus",
        label: "Stochastic Processes & Calculus",
    },
    {
        key: "algorithms_and_numerical_methods",
        label: "Algorithms & Numerical Methods",
    },
    { key: "finance", label: "Finance" },
];

export default function Header({ onSectionSelect }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showHelp, setShowHelp] = useState(false);

    return (
        <header className="bg-gray-900">
            <nav
                className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="text-sm font-semibold leading-6 text-white"
                        >
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <button
                        onClick={() => setShowHelp(!showHelp)}
                        className="text-sm font-semibold leading-6 text-white"
                    >
                        Help
                    </button>

                    <HelpModal open={showHelp} setOpen={setShowHelp} />
                </div>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                    <div className="flex items-center justify-between">
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-400"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-600/25">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <button
                                    onClick={() => setShowHelp(!showHelp)}
                                    className="text-sm font-semibold leading-6 text-white"
                                >
                                    Help
                                </button>
                            </div>
                            <div className="py-6">
                                <h2 className="text-base font-semibold leading-7 text-gray-500">
                                    Sections
                                </h2>
                                <ul className="mt-4 space-y-2">
                                    {sections.map((section) => (
                                        <li key={section.key}>
                                            <button
                                                onClick={() => {
                                                    onSectionSelect(
                                                        section.key
                                                    );
                                                    setMobileMenuOpen(false);
                                                }}
                                                className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                            >
                                                {section.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}
