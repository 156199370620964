import React from "react";
import QuestionBox from "./QuestionBox";
import ToolTip from "./ToolTip";
import { FaRobot } from "react-icons/fa";

export default function QuestionDisplay({
    question,
    questionName,
    aiGenerated,
    solution,
    showSolution,
}) {
    return (
        <div>
            <div className="relative inline-block">
                <QuestionBox
                    question={question || "No question available"}
                    question_name={questionName || "No name available"}
                />
                {aiGenerated && (
                    <div className="absolute top-0 right-0 mr-2">
                        <ToolTip tooltip="AI-generated question">
                            <FaRobot
                                className="text-gray-600 hover:text-gray-700 cursor-pointer"
                                style={{ marginLeft: "8px" }}
                            />
                        </ToolTip>
                    </div>
                )}
            </div>

            {showSolution && (
                <div className="mt-4">
                    <QuestionBox question={solution} question_name="Solution" />
                </div>
            )}
        </div>
    );
}
